<template>
  <div class="bg" style="height: 72vh">
    <div class="box">
      <div class="listBox">
        <div class="mb15">
          <div class="Ttext mb10">回复优先级</div>
          <div class="df mb10" style="width: 430px;float:left;" v-for="(i, idx) in keywordList" :key="idx">
            <div class="replyItem">
              {{ i | getText }}
            </div>
            <el-button style="margin-left: 20px !important; transform: rotate(180deg)" type="primary" icon="el-icon-download" @click="up(idx)"></el-button>
          </div>
        </div>

        <div class="mb15">
          <div class="Ttext mb10">黑名单用户</div>
          <div class="df f-w">
            <el-tag :key="tag.id" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">
              {{ tag.name }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+添加黑名单</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { edit_slive_order, add_blacklist, del_blacklist } from "@/api/slive.js";

export default {
  props: {
    liveInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    liveId: {
      type: Number,
      default: 0,
    },
  },
  filters: {
    getText: (val) => {
      if (!val) return "";
      return val == "lead" ? "定时引导" : val == "welcome" ? "欢迎" : val == "gift" ? "礼物感谢" : "点赞";
    },
  },
  data() {
    return {
      ipt: "",
      inputValue: "",
      inputVisible: false,
      keywordList: [],
      dynamicTags: [],
    };
  },
  watch: {
    liveInfo(nval) {
      console.log(nval.priority);
      this.keywordList = [];
      if (this.dynamicTags !== nval.blacklist_list) this.dynamicTags = nval.blacklist_list;
      this.keywordList = nval.priority;
    },
  },
  methods: {
    back() {
      this.$emit("back");
    },
    up(i) {
      if (i == 0) return;
      this.keywordList.splice(i - 1, 0, this.keywordList[i]);
      this.keywordList.splice(i + 1, 1);
      console.log(this.keywordList);
      edit_slive_order({
        slive_id: this.liveId,
        priority: JSON.stringify(this.keywordList),
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          setTimeout(() => {
            this.$emit("getlive");
          }, 300);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClose(tag) {
      del_blacklist({
        slive_id: this.liveId,
        blacklist_id: tag.id,
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.$emit("getlive");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
        add_blacklist({
          slive_id: this.liveId,
          name: inputValue,
        }).then((res) => {
          if (res.code == "200") {
            this.$message.success(res.msg);
            this.$emit("getlive");
          } else {
            this.$message.error(res.msg);
          }
        });
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-select > .el-input {
  width: 149% !important;
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #f2f3f5;
}

/deep/.el-dialog__body {
  padding: 20px;
}

.replyItem {
  background: rgba(15, 18, 25, 1);
  color: rgba(216, 216, 216, 0.8);
  border-radius: 6px;
  width: 320px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.el-button {
  padding: 12px;
}

.back {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  background: #f2f7fe;
  margin-top: 8px;
  line-height: 45px;

  img {
    width: 24px;
    height: 24px;
  }
}
.listBox {
  background: #fff;
  text-align: left;
  padding: 15px;
  border-radius: 5px;
  /*height: 77vh;*/
}

.listBox1 {
  background: #fff;
  width: 100%;
  padding: 15px;
  margin: 0 0 20px;
  border-radius: 2px;
  display: flex;

  .info {
    margin-left: 20px;
    text-align: left;
    width: 86%;

    .tit {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .btn {
      background: #d9edf5;
      padding: 1px 7px;
      color: #4284cf;
      border: 1px solid #4284cf;
      border-radius: 4px;
      margin-right: 6px;
    }
  }

  img {
    width: 90px;
    height: 160px;
    border-radius: 8px;
    overflow: hidden;
  }

  .tools {
    position: relative;
    z-index: 99;
    width: 27px;
    height: 27px;
    color: #ff5733;
    line-height: 27px;
    background: #fff;
    border: 1px solid #c8dfff;
    border-radius: 4px;
    top: 0px;
    left: 15px;
  }
}

.listBox1:last-child {
  margin-bottom: 0;
}

.item {
  margin-right: 15px;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 5px;
  height: 315px;

  .desc {
    padding: 10px;
    text-align: left;
  }

  img {
    width: 170px;
    height: 255px;
    border-radius: 5px 5px 0 0;
  }
  .tools {
    position: relative;
    z-index: 99;
    width: 27px;
    height: 27px;
    color: #ff5733;
    line-height: 27px;
    background: #fff;
    border: 1px solid #c8dfff;
    border-radius: 4px;
    top: -310px;
    left: 135px;
  }
}

.item:last-child,
.item:nth-child(6n) {
  margin-right: 0;
}

.ipt {
  width: 32%;
  position: absolute;
  top: 3%;
  right: 2%;

  /deep/.el-input__inner {
    background: #f1f4f9 !important;
  }
}

.dlistBox {
  height: 59vh;
  overflow: scroll;
  padding: 10px 20px 0;
  margin-bottom: -20px;
  .tools {
    border: 0 !important;
    background: transparent;
  }
  .check {
    align-self: center;
  }
  /deep/.el-checkbox-group {
    font-size: 15px !important;
  }
  /deep/.el-checkbox__inner {
    width: 18px;
    height: 18px;
  }
  /deep/.el-checkbox__inner::after {
    height: 10px;
    left: 6px;
  }
  .item {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  }
  .item:nth-child(5n) {
    margin-right: 0;
  }
  .item:nth-child(6n) {
    margin-right: 15px;
  }
}
::v-deep{
  .el-button--primary{
    border: none;
    background: rgba(4, 55, 46, 1) !important;
    margin-left: 10px;
  }
  [data-v-6e2c4711] .el-button{
    background: rgba(147, 159, 157, 0.25);
    color: white;
    /*margin-left: -10px;*/
    border: 1.4px solid rgba(102, 102, 102, 0.6);
  }
  .el-button{
    background: rgba(147, 159, 157, 0.25) !important;
    color: white !important;
    /*margin-left: -10px;*/
    border: 1.4px solid rgba(102, 102, 102, 0.6) !important;
  }
}
  /deep/.mb15{
    float: left;
  }
</style>
