<template>
  <div class="bg" style="height: 72vh">
    <div class="box">
      <div class="listBox">
        <div class="mb10">
          <div class="Ttext mb10">是否开启</div>
          <div style="width: 320px">
            <el-switch v-model="value" active-color="#025ee9" inactive-color="#909399" @change="change"> </el-switch>
          </div>
        </div>
        <div class="mb10">
          <div class="Ttext mb10">回复语句</div>
          <div class="df mb10" style="width: 360px;float: left" v-for="(i, idx) in keywordList" :key="idx">
            <el-input v-model="i.text" placeholder="请输入" @blur="saveIpt(i)"></el-input>
            <el-button style="margin-left: 20px" type="info" icon="el-icon-delete" @click="delKey(i.id)"></el-button>
          </div>
          <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addKey">添加</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { set_interaction, add_interaction, edit_interaction, del_interaction } from "@/api/slive.js";
export default {
  props: {
    liveInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    liveId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      value: true,
      keywordList: [],
    };
  },
  watch: {
    liveInfo(nval) {
      if (nval != "") {
        console.log(nval);
        this.keywordList = [];
        this.value = nval.is_gift == 1 ? true : false;
        this.keywordList.push(...nval.gift_texts);
      }
    },
  },
  methods: {
    change(e) {
      set_interaction({
        slive_id: this.liveId,
        is_on: e ? 1 : 0,
        types: 2,
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.$emit("getlive");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    addKey() {
      add_interaction({
        slive_id: this.liveId,
        types: 2,
        text: " ",
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.$emit("getlive");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    delKey(i) {
      del_interaction({
        slive_id: this.liveId,
        types: 2,
        text_id: i,
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.$emit("getlive");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    saveIpt(i) {
      console.log(111, i);
      if (!i.text) {
        this.$message.error("请输入回复语句");
        return;
      }
      edit_interaction({
        text_id: i.id,
        types: 2,
        text: i.text,
      }).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.$emit("getlive");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
  border: 0;
  color: #939dae;
  background: #0f1219 !important;
}

.back {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  background: #f2f7fe;
  margin-top: 8px;
  line-height: 45px;

  img {
    width: 24px;
    height: 24px;
  }
}
.listBox {
  background: #fff;
  text-align: left;
  width: 99%;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}
  /deep/.mb10{
    margin-right: 50px;
  }
</style>
