import request from "@/utils/request.js";
import axios from "axios";
let fetUrl = localStorage.getItem("fetUrl");
export const add_slive = (params) => request.post("/api/Slive/add_slive", params);
export const get_list_slive = (params) => request.post("/api/Slive/get_list_slive", params);
export const get_slive = (params) => request.post("/api/Slive/get_slive", params);
export const edit_slive = (params) => request.post("/api/Slive/edit_slive", params);
export const del_slive = (params) => request.post("/api/Slive/del_slive", params);
export const get_slive_config = (params) => request.post("/api/Slive/get_slive_config", params);
export const edit_slive_order = (params) => request.post("/api/Slive/edit_slive_order", params);
export const add_blacklist = (params) => request.post("/api/Slive/add_blacklist", params);
export const del_blacklist = (params) => request.post("/api/Slive/del_blacklist", params);
export const set_interaction = (params) => request.post("/api/Slive/set_interaction", params);
export const add_interaction = (params) => request.post("/api/Slive/add_interaction", params);
export const edit_interaction = (params) => request.post("/api/Slive/edit_interaction", params);
export const del_interaction = (params) => request.post("/api/Slive/del_interaction", params);
export const add_goods = (params) => request.post("/api/Slivegoods/add_goods", params);
export const edit_goods = (params) => request.post("/api/Slivegoods/edit_goods", params);
export const del_goods = (params) => request.post("/api/Slivegoods/del_goods", params);
export const get_goods = (params) => request.post("/api/Slivegoods/get_goods", params);
export const get_goods_list = (params) => request.post("/api/Slivegoods/get_goods_list", params);
export const get_ai_cfn = (params) => request.post("/api/slivego/get_ai_cfn", params);
export const get_image_list = (params) => request.post("/api/Realimage/get_image_list", params);
export const check_captions = (params) => request.post("/api/slivego/check_captions", params);
export const edit_boot_time = (params) => request.post("/api/Slive/edit_boot_time", params);
export const local_doc_chats = (params) => axios.post(fetUrl + "api/local_doc_qa/local_doc_chats", params);
